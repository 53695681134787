import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoHero from '../components/VideoHero'
import ProjectThumbnail from "../components/ProjectThumbnail"
import ConnectSection from "../components/connectSection"

const ProjectIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const nodes = data.allMarkdownRemark.nodes.slice(0, 9)

  if (nodes.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          There are no Projects. Please add to projects to "content/project"
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="text-base text-zinc-100">
        <div className="overflow-hidden max-w-6xl">
          <div className="mb-20">
            
            <div className="">
            <h1 className="font-bold text-5xl mb-10">Flatblack® is an Integrated Studio + Manufacturing company located in Los Angeles.</h1>
            <p className="my-5">We specialize in creating experiences with merchandise that build memorable connections with customers.  Merchandise is more than a physical product and serves as the vehicle to transmit stories from creators to their community.</p>
            <p className="my-5">Our focus is brand building.  We understand that each product release is part of a larger story.  We work with our partners to create unique offerings that are authentic to their brand and will resonate with their audiences.</p>
            <p className="my-5">By offering a full range of creative, manufacturing, and shipping capabilities, Flatblack enables our partners to expand their brand, by gaining a team to work hand-in-hand each step of the process.</p>
            <Link to="/about"><button className="mt-5 pb-1 px-0 text-2xl border-white border-b font-medium">Read more</button></Link>
            </div>

          <div className="">
              <h2 className="font-bold text-5xl border-b border-white pb-5 my-10">Projects</h2>
              <p className="my-5">Examples and case studies of our past work</p>
              <div className="grid px-0 py-10 auto-rows-fr grid-cols-2 gap-8 lg:max-w-none lg:grid-cols-3">
                {nodes.map(node => (
                  <ProjectThumbnail key={node.fields.slug} node={node} />
                ))}
              </div>
              <Link to="/project"><button className="mt-5 pb-1 px-0 text-2xl border-white border-b font-medium">See our Projects</button></Link>
            </div>
          </div>
          
          <div className="mb-20">
            <ConnectSection />
          </div>

          {/* <div className="mb-20 drop-shadow-xl">
            <VideoHero />
          </div> */}

          <div className="md:mb-20">
            <h2 className="font-bold text-5xl border-b border-white pb-5 my-10">Services & Capabilities</h2>
            <ul role="list" className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-3 mt-8">
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-2xl font-bold mb-3 uppercase">DESIGN</h3>
                  <p className="text-base">Our team brings creative visions to life through fresh design and thoughtful product development. From eye-catching graphics and custom illustrations to distinctive logos and carefully crafted products, we make your ideas a reality. On the branding side, we create cohesive brand identities with sleek packaging, clear brand guidelines, and a unique visual style that ensures your business stands out and connects with your audience.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-2xl font-bold mb-3 uppercase">PRODUCT DEVELOPMENT + MFG</h3>
                  <p className="text-base">From sourcing and sampling to development and full-scale production, we've got every step covered to bring your ideas to life with top-notch quality.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-2xl font-bold mb-3 uppercase">CONTENT CREATION (PHOTO / VIDEO)</h3>
                  <p className="text-base">Our in-house photo and video team creates high-quality content for product shoots, lookbooks, and campaign videos. We also offer these services to external clients, providing professional visuals to enhance your brand and elevate your product launches.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-2xl font-bold mb-3 uppercase">WEB DEVELOPMENT / E-COMMERCE</h3>
                  <p className="text-base">Power your online presence with our expert web development and e-commerce solutions. From sleek websites to seamless shopping experiences, we leverage cutting-edge technology to help you succeed in the digital marketplace.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-2xl font-bold mb-3 uppercase">XP / EVENTS</h3>
                  <p className="text-base">We specialize in crafting unforgettable experiences with immersive activations, custom booth designs, and live events that drive engagement and leave a lasting impression.</p>
                </div>
              </li>
              <li className="relative overflow-hidden">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden">
                  <h3 className="text-2xl font-bold mb-3 uppercase">FULFILLMENT + LOGISTICS</h3>
                  <p className="text-base">We’ve got all your logistics covered with precision and care. From secure warehousing and flexible shipping options—including drop shipping to individual customers and bulk shipping to retailers—to top-notch in-house customer service, we handle everything to ensure a smooth process from start to finish. We also carefully package your items and provide UPC coding to meet your product labeling needs.</p>
                </div>
              </li>
            </ul> 
          </div>
          
        </div>
      </div>
    </Layout>
  )
}

export default ProjectIndex

export const Head = () => <Seo title="FLATBLACK" />

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/project/" } } 
      sort: { fields: frontmatter___date, order: DESC }
      limit: 20
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          thumbnail
        }
      }
    }
  }
`
